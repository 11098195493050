/* Mixins */
.org-cont[data-v-065f425c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 30px;
  margin-bottom: 15px;
}
.org-cont .required[data-v-065f425c] {
  color: #f5222d;
  margin-right: 2px;
  vertical-align: middle;
}
.label[data-v-065f425c] {
  padding-right: 10px;
  text-align: right;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 130px;
}
.page-table-search__input[data-v-065f425c] {
  margin-left: 15px;
}